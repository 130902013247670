@font-face {
  font-family: 'Katanga-Medium';
  src: local('Gotham-Rounded'), url(./fonts/GothamRoundedMedium_21022.ttf) format('truetype');
}
@font-face {
  font-family: 'Katanga-Book';
  src: local('Gotham-Rounded'), url(./fonts/GothamRoundedBook_21018.ttf) format('truetype');
}
@font-face {
  font-family: 'Katanga-Light';
  src: local('Gotham-Rounded'), url(./fonts/GothamRoundedLight_21020.ttf) format('truetype');
}
@font-face {
  font-family: 'Katanga-Bold';
  src: local('Gotham-Rounded'), url(./fonts/GothamRoundedBold_21016.ttf) format('truetype');
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

span.text-body.pl-2.pr-2 {
  padding-left: 0.75em
}